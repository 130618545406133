<template>
    <v-row no-gutters>
        <div class="hb-inline-sentence-text" v-if="thIndex === 0">
           0
        </div>
        <div class="hb-inline-sentence-text" v-if="thIndex != 0">
            {{ tiers.start_tier }}
        </div>
        <div class="mr-rt" >
            -
        </div>
        <hb-text-field condensed box medium v-model="tiers.end_tier" v-on:change="onChange(thIndex)" v-validate="'required|min_value:0|max_value: 2000|numeric'" data-vv-scope="general"
            data-vv-name="tier" data-vv-as="tier" :error="errors.has('general.tier')" placeholder="0"  class="end-tier-input"
        >
        </hb-text-field>
        <HbBtn class="mt-n1 mx-n2" icon tooltip="Clear"  mdi-code="mdi-close-circle" @click="$emit('delete-tier', thIndex)"/>
    </v-row>
</template>
<script>
export default {
    name: 'addTier',
    data() {
        return {
        };
    },
    computed: {
        tiers: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    props: ['value','tier','thIndex'],
    methods: {
        onChange(index){
            this.$emit('onChange', this.value, index);
        }
    }
}
</script>
<style lang="scss" scoped>
.hb-inline-sentence-text{
    min-width: 55px;
}

.mr-rt{
    margin-right: 23px;
}
</style>