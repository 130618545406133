<template>
  <div class="rm-content">
    <div class="primary-section-content">
      <div class="mr-6">
        <v-expansion-panels
          elevation="0"
          class="hb-expansion-panel"
          v-model="panel"
          :key="level" 
        >
          <hb-notification
            v-if="!hasPermission('enable_disable_autopay')"
            type="caution"
            :notDismissable="true"
          >
            You do not have permission to edit/save the account information.
          </hb-notification>
          <hb-expansion-panel v-if="!isPropertyLevel" key="rent">
            <template v-slot:title> Account Information</template>
            <template v-slot:content>
              <hb-form label="Direct Debit*">
                <div class="hb-text-night-light">
                  Provide details for your direct debit account
                </div>
                <v-col cols="12">
                  <v-text-field
                    hide-details
                    :disabled="!hasPermission('enable_disable_autopay')"
                    :class="{ 'custom-field-error': errors.first('account_name') }"
                    v-model="payment_method.account_name"
                    v-validate="'required|max:32'"
                    data-vv-name="account_name"
                    data-vv-as="Account Name"
                    :error-messages="errors.collect('account_name')"
                    label="Account Name*"
                    id="account-name"
                    name="account-name"
                    autocomplete="cc-account-name"
                    @input="handleInputUpdate('payment_method', 'account-name', $event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :disabled="!hasPermission('enable_disable_autopay')"
                    :class="{ 'custom-field-error': errors.first('bsb_number') }"
                    tile
                    hide-details
                    v-model="payment_method.bsb_number"
                    data-vv-name="bsb_number"
                    v-validate="'required|numeric|length:6'"
                    maxlength="6"
                    data-vv-as="BSB Number"
                    :error-messages="errors.collect('bsb_number')"
                    label="BSB Number*"
                    id="account-bsb-number"
                    name="account-bsb-number"
                    autocomplete="cc-account-bsb-number"
                    @input="handleInputUpdate('payment_method', 'bsb_number', $event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :disabled="!hasPermission('enable_disable_autopay')"
                    :class="{ 'custom-field-error': errors.first('account_number') }"
                    tile
                    hide-details
                    v-model="payment_method.account_number"
                    data-vv-name="account_number"
                    v-validate="'required|numeric|min:4|max:10|'"
                    minlength="4"
                    maxlength="10"
                    data-vv-as="Account Number"
                    :error-messages="errors.collect('account_number')"
                    label="Account Number*"
                    id="account-number"
                    name="account-number"
                    autocomplete="cc-account-number"
                    @input="handleInputUpdate('payment_method', 'account_number', $event)"
                  >
                  </v-text-field>
                </v-col>
              </hb-form>
              <hb-form label="Autopay">
                <hb-switch
                  :disabled="!hasPermission('enable_disable_autopay')"
                  v-model="payment_method.enable_auto_pay"
                  label="Enable Autopay"
                >
                </hb-switch>
              </hb-form>
              <hb-bottom-action-bar @close="fetchData">
                <template v-slot:right-actions>
                  <hb-btn
                    @click="logdata"
                    :disabled="
                      errors.items.length > 0 ||
                      !hasRolePermission('enable_disable_autopay')
                    "
                    >Save</hb-btn
                  >
                </template>
              </hb-bottom-action-bar>
            </template>
          </hb-expansion-panel>
          <hb-expansion-panel>
            <template v-slot:title> SQM Discount Tier Defaults</template>
            <template v-slot:content>
              <hb-form label="Set Discount Tiers" editable :active.sync="active" hide-actions>
                  <template v-slot:display>
                    <div v-if="(form.tier_details[0].end_tier != 0)">
                      <li v-for="(tiers, index) in form.tier_details" :key="index">
                      <div class="m_class">
                        <div class="mr" style="width: 35px;">{{ tiers.start_tier }}</div> 
                        <div class="mr">-</div> 
                        <div class="mr" style="margin-left: 15px;">{{ tiers.end_tier}}</div> 
                      </div>
                    </li>
                    <div> 
                      <div class="mr">{{ form.tier_details[form.tier_details.length - 1].end_tier }}+</div>
                    </div>
                    </div>
                    <div v-else>
                      No Tiers Added
                    </div>
                  </template>
                  <template v-slot:edit>
                       <div class="hb-text-night-light cstm-wt">
                           Set ranges to create your default SQM tiers.
                       </div>
                      <div v-if="form?.tier_details?.length">
                        <add-tier
                          v-for="(tier, index) in form.tier_details"
                          ref="addTier"
                          :key="index"
                          :thIndex="index"
                          
                          @onChange="onTierValueChange"
                          v-model="form.tier_details[index]"
                          @delete-tier="removeTier" 
                        ></add-tier>
                      </div>
                      <hb-link @click="addTiers" v-show="form?.tier_details?.length <= 9">+ Add Tier</hb-link>
                  </template>
              </hb-form>
              <hb-bottom-action-bar @close="tiersClose()">
                <template v-slot:right-actions>
                  <hb-btn @click="saveTiers" :disabled="(isDisabled || active === '')"
                    >Save</hb-btn
                  >
                </template>
              </hb-bottom-action-bar>
            </template>
          </hb-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import addTier from "./addTier.vue";
import api from "../../../assets/api.js";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
  name: "national-accounts",
  mixins: [notificationMixin],
  props: {
    level: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      payment_method: {
        account_number: "",
        bsb_number: "",
        account_name: "",
        enable_auto_pay: "",
      },
      form: { tier_details: [] },
      active: "",
      isDisabled: true,
      allTiers: []
    };
  },
  components: {
    addTier,
  },
  created() {
    this.fetchData();
  },
  async mounted(){
    await this.fetchAllTiers();
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
    }),
    currentLevel() {
      return this.level.toLowerCase();
    },

    isPropertyLevel() {
      return this.level === "Property";
    },
  },

  watch: {
    isPropertyLevel: {
      async handler(val) {
        if (val) {
          if (this.properties.length > 1) {
            this.propertyId = "";
            this.hasProperties = true;
          } else if (this.properties.length === 1) {
            this.propertyId = this.properties[0].id;
            this.hasProperties = false;
          } else this.propertyId = "";
          if (this.propertyId) await this.getDocumentList();
        }
      },
      immediate: true,
    },
    propertyId: {
      async handler(val) {
        if (this.isPropertyLevel && val) {
          this.propertyLoading = true;
          this.revenueArray = [];
          await this.getDocumentList();
          await this.fetchPropertySpaceGroup();
        }
      },
      immediate: true,
    },
    rentEnabled: {
      handler(val) {
        if (val) {
          this.readonlyRent = true;
        }
      },
      immediate: true,
    },
  },

  methods: {
    async fetchData() {
      let data = await api.get(this, api.NATIONAL_ACCOUNTS);
      this.payment_method = data;
    },
    async logdata() {
      await api.put(this, api.NATIONAL_ACCOUNTS, this.payment_method);
      this.showMessageNotification({
        type: "success",
        description: "You updated Direct Debit information.",
      });
    },

    // on Tier value change
    async onTierValueChange(value, index){
      let data = this.form.tier_details;
      if(this.form.tier_details[index+1]){
        this.form.tier_details[index+1]['start_tier'] = this.form.tier_details[index].end_tier || 0;
         this.form.tier_details = [...data];
      }
      this.isDisabled = false;
    },

    // On Add Tiers
    async addTiers(index) {
      this.$validator.errors.clear();
      await this.$validator.validateAll('default');
      index = this.form.tier_details.length > 0 ? this.form.tier_details.length : 0;
      if (index === 0) {
        this.form.tier_details.push({
          start_tier: 0,
          end_tier: 0,
        });
      } else if (index > 0) {
        const lastTierValue = this.form.tier_details[this.form.tier_details.length - 1];
          this.form.tier_details.push({
           start_tier: Number(lastTierValue.end_tier),
           end_tier: 0,
         });
      }
      this.isDisabled = false;
    },
    // On Remove Tiers
    removeTier(index) {
      this.form.tier_details.splice(index, 1);
      for (let i = index; i < this.form.tier_details.length; i++) {
       const previousTier = this.form.tier_details[i - 1];
       const currentTier = this.form.tier_details[i];
       currentTier.start_tier = Number(previousTier.end_tier);
       currentTier.end_tier = currentTier.end_tier;
      }
      if((this.allTiers.length && this.allTiers.length != this.form.tier_details.length) || (this.form.tier_details.length != 0 && this.allTiers.length != 0)){
        this.isDisabled = false;
      }
      else this.isDisabled = true; 
    },
    // On Save Tiers
    async saveTiers(){
      let data = [];
      this.form.tier_details.forEach((t, i) =>{
        data.push({start_tier: Number(t.start_tier), end_tier: Number(t.end_tier), tier_id: i+1})
      })
      let valid = await this.validateOnsave(data);
      if(valid){
        try{
          await api.post(this, api.NATIONAL_ACCOUNTS_SQM_TIER, data);
           this.showMessageNotification({
           type: "success",
           description: "SQM Tier Details Saved.",
           });
           this.isDisabled = true;
           await this.fetchAllTiers()
           this.active = "";
        }
        catch(error){
        }
      }
    },
    // fetch all tiers
    async fetchAllTiers(){
      this.allTiers = await api.get(this, api.NATIONAL_ACCOUNTS_SQM_TIER_LIST);
      this.form.tier_details = this.allTiers.length? [...this.allTiers] : [{start_tier: 0, end_tier:0}];
      if(this.allTiers.length != this.form.tier_details.length) this.isDisabled = false;
      else this.isDisabled = true;
      if(this.allTiers.length === 0 && this.form.tier_details.length ===0){
        this.active = '';
        this.isDisabled = true;
      }
    },

    // on cancel
    async tiersClose(){
      await this.fetchAllTiers()
      this.active = '';
    },

    async validateOnsave(data){
      let error = await this.$validator.validateAll('default');
      // Maximum value for a tier - 2000
      const max_value = data.find(d => d.end_tier > 2000 || d.start_tier > 2000);
      // Allow only numbers
      const num_regex = /[A-Za-z]/;
      const invalid_char = data.find(d => num_regex.test(d.end_tier));
      // Allow only positive numbers
      const positive_regex = /^[0-9]+$/;
      const invalid_num = data.find(d => !positive_regex.test(d.end_tier));
      // check for zero value
      const invalid = data.find((d,i) => d.end_tier === 0 || (d.start_tier === 0 && !i===0));
      this.$validator.errors.clear();
      if(max_value && !this.isErrorAlreadyAdded('end_tier', 'Tier value exceeds 2000.')){
        this.$validator.errors.add({
                    field: 'end_tier',
                    msg: 'Tier value exceeds 2000.'
                });
      }
      if(invalid_char && !this.isErrorAlreadyAdded('end_tier', 'Tier value must be numeric.')){
        this.$validator.errors.add({
                    field: 'end_tier',
                    msg: 'Tier value must be numeric.'
                });
      }
      if(invalid_num !== undefined && !this.isErrorAlreadyAdded('end_tier', 'Tier value must be an integer.')){
        this.$validator.errors.add({
                    field: 'end_tier',
                    msg: 'Tier value must be an integer.'
                });
      }
      if(invalid && !this.isErrorAlreadyAdded('end_tier', 'Tier value cannot be 0.')){
        this.$validator.errors.add({
                    field: 'end_tier',
                    msg: 'Tier value cannot be 0.'
                });
      }
      // Check errors for each item 
      let isValid = true;
      for (let i = 0; i < data.length; i++) {
        const lastTierValue = data[i];
        const isError = await this.validateOnadd(lastTierValue, i);
        if (isError) {
          isValid = false;
        }
      }
      if(error && !invalid_char && !max_value && !invalid_num && !invalid && isValid) return true;
      else return false;
    },
    async validateOnadd(lastTierValue, index){
       //  check for zero value
       if(lastTierValue.end_tier === 0 || lastTierValue.end_tier <= lastTierValue.start_tier){
        if(!this.isErrorAlreadyAdded('end_tier', `Right Tier should be greater than Left Tier.`)){
          this.$validator.errors.add({
                  field: 'end_tier',
                   msg: `Right Tier should be greater than Left Tier.`
              });
           return true;
        }
      }
    },
    isErrorAlreadyAdded(field, message) {
       const existingError = this.$validator.errors.items.find(err => err.field === field && err.msg === message);
        return !!existingError;
    }
  },
};
</script>

<style lang="scss">
.notificationMsg {
  textarea {
    line-height: 35px;
  }
}

.merge-field-text-wrap {
  a {
    i {
      margin-bottom: 2px;
    }
  }
}

.cstm-wt {
  width: 500px;
  margin-bottom: 10px !important;
}

.mr{
  margin:10px;
}
.m_class{
  display: flex !important;
}
</style>
